



















































import * as vr from '@/helpers/validation';
import { ValidationRules, EPromoCampaignType } from '@/api/schema';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

export default {
  name: 'PromoCampaignFormDeadline',
  components: {
    VExtendedDataPicker
  },
  mixins: [PromoCampaignFormMixin],

  computed: {
    isDepositCampaignType(): boolean {
      return this.formData.campaignType === EPromoCampaignType.DEPOSIT;
    },

    isCashbackCampaignType(): boolean {
      return this.formData.campaignType === EPromoCampaignType.CASHBACK;
    },

    rules(): ValidationRules {
      return {
        deadlineDateSelect: this.isDepositCampaignType ? [vr.required] : []
      };
    }
  },

  methods: {
    updateDate(key: string, val: string): void {
      this.updateFormData(key, val);
    },

    clearField(key: string): void {
      this.$refs[key].blur();
      this.updateFormData(key, null);
    }
  }
};
